$primary-blue-color: #004157;
$secondary-blue-color: #004150;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mulish', sans-serif;
}

html {
    height: 100%;
}

body {
    min-height: 100vh;
}

.yellow{
    color: #f2a640;
}

.danger-l {
    color: #7a2931;
}

.warning-l {
    color: #dba032;
}

.ok-l {
    color: #2e7d32;
}

.bg-danger-l {
  background-color: #7a2931!important;
}

.bg-warning-l {
  background-color: #dba032!important;;
}

.bg-ok-l {
  background-color: #2e7d32!important;;
}

.labelRange {
    border: solid 0.8px #dce2ea;
    padding: 11px 21px 11px 24px;
    border-radius: 6px;
    background-color: #f1f3f7;
}

.bg-primary-blue {
    background-color: $primary-blue-color;
}

.bg-primary-green {
    background-color: $primary-blue-color;
}

.txt-white{
  color: #fff!important;
}

.bg-secondary-green {
    background-color: $secondary-blue-color;
}

.btn-descargar:hover {
    color: $secondary-blue-color;
    background-color: #fff;
    border-color: $primary-blue-color;
    border-radius: 1rem;
    border: 1px solid;
}

.btn-descargar:active {
    color: #fff;
    background-color: $secondary-blue-color;
    border-color: $primary-blue-color;
    border-radius: 1rem;
    border: 1px solid;
}

.btn-descargar:focus {
    color: #fff;
    background-color: $secondary-blue-color;
    border-color: $primary-blue-color;
    border-radius: 1rem;
    border: 1px solid;
    box-shadow: none;
}

.btn-descargar {
    color: #fff;
    background-color: $secondary-blue-color;
    border-color: $primary-blue-color;
    border-radius: 1rem;
    border: 1px solid;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: $primary-blue-color;
    background-color: #fff;
    border: 1px solid;
    border-color: $primary-blue-color;
}

.nav-pills .nav-link, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: $secondary-blue-color;
    border: 1px solid;
    border-color: $primary-blue-color;
    margin: 5px 0px;
    border-radius: 1rem;
}

.btn-home-start{
    border: 2px solid !important;
    border-color: $primary-blue-color;
    background-color: $primary-blue-color;
    color: #fff !important;
    border-radius: 1rem;
    font-weight: 900;
}

.btn-home-start:hover{
    border: 2px solid !important;
    border-color: $primary-blue-color;
    background-color: $primary-blue-color;
    color: #fff !important;
    border-radius: 1rem;
    font-weight: 900;
}

.fc-secondary{
    color: #74797a;
}

.form-check-input:checked {
    background-color: $primary-blue-color;
    border-color: $primary-blue-color;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-control:focus, .form-select:focus {
    box-shadow: none;
    border-color: $primary-blue-color;
}

.progress-bar {
    background-color: $secondary-blue-color;
}

.btn-outline-primary {
    color: $primary-blue-color;
    border-color: $primary-blue-color;
}


.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: $secondary-blue-color;
    border-color: $secondary-blue-color;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: $secondary-blue-color;
    border-color: $secondary-blue-color;
}

.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: none !important;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: none;
}

.range-input{
    accent-color:$secondary-blue-color;
}

.img-head-watermark{
    opacity: 0.5;
}

.img-logo-modals-goals{
    margin-top: 20px;
    width: 250px;
}

.text-justify {
    text-align: justify;
}

.header{
    background-color: #004155 !important;
    h1{
        color: #ffffff;
        font-weight: bolder;
    }
}

@media print {
    body {
        background-color: #ffffff !important;
        font-size: small;
    }

    .btn-print{
        display: none;
    }

    .header{
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .nav-link {
        width: 400px !important;
    }
  }
